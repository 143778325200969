<template>
    <!-- loading overlay -->
    <div class="fixed top-0 left-0 h-full w-full flex items-center justify-center flex-col gap-1 bg-white bg-opacity-80"
        v-if="useDiaryStore().free_line_products_loading">
        <i class="fa fa-spinner fa-spin fa-2x"></i>
        <span class="font-thin">
        Po ngarkohet...
        </span>
    </div>
    <div class="flex items-center justify-center bg-blue-500 pt-2 pb-1 mb-2">
        <h1 class="text-white font-semibold">Kerkese produktesh</h1>
    </div>
    <div class="bg-slate-100 px-1 flex text-14px">
        <span class="w-1/5"> Ditari </span>
        <span class="w-1/5"> Krijuar nga </span>
        <span class="w-1/5"> Klienti </span>
        <span class="w-1/5"> Krijuar me </span>
        <span class="w-1/5"> POS </span>
    </div>
    <div v-if="Object.keys(useDiaryStore().free_line_list).length > 0">
        <!-- <pre> {{useDiaryStore().free_line_list}} </pre> -->
        <div v-for="(key, indexa) in Object.keys(useDiaryStore().free_line_list)" :key='indexa'
            class="border rounded p-1 bg-slate-100 my-2">
            <div class="w-full flex mb-2 text-14px flex">
                <div class="w-1/5"> <i class="fa-solid fa-hashtag"></i> {{useDiaryStore().free_line_list[key][0]?.diary_number}} </div>
                <div class="w-1/5"> {{useDiaryStore().free_line_list[key][0].created_by_name}} </div>
                <div class="w-1/5"> {{useDiaryStore().free_line_list[key][0].customer_name}} </div>
                <div class="w-1/5"> {{useDiaryStore().formatDate(useDiaryStore().free_line_list[key][0].diary_created_at)}} </div>
                <div class="w-1/5"> {{useDiaryStore().free_line_list[key][0].pos_name}} </div>
            </div>
            <div v-for="(p, index) in useDiaryStore().free_line_list[key]" :key="index"
                class="mb-2 rounded border bg-white  flex justify-between items-center px-1">
                <span> {{p?.product_description}} </span>
                <span @click="start_replace_product(p)" class="border bg-white px-3 my-1 text-slate-500">
                    <i class="fa-solid fa-retweet"></i>
                </span>
            </div>
        </div>
    </div>

    <!-- BLOCK: Search Product Modal Form -->
    <div class="h-screen w-screen fixed top-0 left-0 bg-black bg-opacity-50 flex items-start justify-center"
      v-if="toggleAddProduct" @click.self="toggleAddProduct = false">
      <div class="p-2 bg-white mt-20 flex-col products-search w-1/3">
        <div v-if="useDiaryStore().selected_diary_detail" class="text-14px flex flex-col">
            <span class="text-10px font-semibold">
                # {{useDiaryStore().selected_diary_detail?.diary_number}}
            </span>
            <span>
                {{useDiaryStore().selected_diary_detail?.product_description}}
            </span>
        </div>
        <!-- Search input -->
        <div class="flex items-center relative">
          <div class="w-full">
            <!-- Add product from stock -->
            <input type="text" placeholder="Kerko produktin..." class="border border-gray-300 p-1  text-xs"
              @keydown.enter="useSearchProcess().search_product_diary(process)" v-model="useSearchProcess().search_val">
            <span class="absolute top-0 bottom-0 right-4 h-full flex items-center">
              <i class="fa fa-search fa-xs"></i>
            </span>
          </div>
        </div>
        <!-- Search results -->
        <div class="bg-white">
          <div class="flex w-full">
            <span v-if="useSearchProcess().search_results.length > 0" class="ml-auto text-xs mb-1">Stock</span>
          </div>
          <div v-for="result in useSearchProcess().search_results" :key="result.guid" :class="[result?.expanded ? 'border-4 border-green-5003' : '']">
            <div class="product border p-1 hover:bg-sky-200 flex items-center justify-between bg-gray-100"
               :class="[result.expanded ? 'bg-green-50 border-b-2 border-t-2 border-green-600' : '']">
              <span @click="expandProduct(result)" 
                class="flex-grow">
                {{ result.name }}
              </span>
              <span>
                <!-- <span>{{ numeral(result.price).format() }}</span> -->
                <span>{{ useDiaryStore().price_filter(result.price) }}</span>
                <span @click="useDiaryStore().toggle_result_active(result, null)" class="ml-5" style="text-transform: lowercase;">
                  <i v-if="result.is_active" class="fa-regular fa-square-check"></i>
                  <i v-else class="fa-regular fa-square"></i>
                </span>
              </span>
            </div>
            <div class="product-expanded bg-white" v-if="result.expanded">
              <transition name="fade">
                <div class="text-xs uppercase border-l border-r p-1 bg-green-50 font-thin"
                  v-if="result.accesories?.length > 0">
                  Aksesorët
                </div>
                <div v-else class="text-xs uppercase border-l border-r p-1 bg-gray-50 text-gray-400">
                  Nuk ka aksesorë
                </div>
              </transition>
              <div v-for="accessory in result.accesories" :key="accessory.guid"
                  :class="[accessory.included ? 'bg-green-50' : '']"
                  class="accessory border p-1 py-2 hover:bg-sky-200 flex items-center justify-between bg-white text-xs">
                <span @click="includeAccessory(accessory, result)" class="gorw">
                  <span class="cursor-pointer">
                    <i class="fa-regular fa-square-check" v-if="accessory.included"></i>
                    <i class="fa-regular fa-square" v-else></i>
                  </span>
                  <span class="flex-1 ml-1">
                    {{ accessory.name }}
                  </span>
                </span>
                <span>
                  <!-- <span>{{ numeral(accessory.price).format() }}</span> -->
                  <span>{{ useDiaryStore().price_filter(accessory.price) }}</span>
                  <span @click="useDiaryStore().toggle_result_active(result, accessory)"
                    class="pl-5">
                    <i v-if="accessory.is_active" class="fa-regular fa-square-check"></i>
                    <i v-else class="fa-regular fa-square"></i>
                  </span>
                </span>
              </div>
              <div class="flex justify-between items-center border-t border-gray-300 p-1 bg-green-50">
                <span class="font-bold text-xs uppercase">Totali</span>
                <!-- <span class="flex-1 ml-2">{{ numeral(result.price + (result?.accessories_total ? result.accessories_total : 0)).format() }}</span> -->
                <span class="flex-1 ml-2">{{ useDiaryStore().price_filter(result.price + (result?.accessories_total ? result.accessories_total : 0)) }}</span>
                
                <span class="font-thin text-xs uppercase mr-2" v-if="result?.accessories_included > 0">+ {{ numeral(result?.accessories_total).format()}}</span>
                <button @click.prevent="add_diary_product_start(result) "
                  class="btn btn-sm text-gray-600 hover:border-black border p-1 bg-orange-300 shadow-md hover:bg-green-300 text-xs">
                  <i class="fa fa-plus mr-1"></i> Shto {{ result?.accessories_included > 0 ? (result?.accessories_included + 1) + ' Produkte' : 'Produkt' }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>



    
</template>

<script setup>

import { 
    // onBeforeUnmount , watch 
    onMounted, 
    reactive,
    ref,
    inject
} from 'vue'
import { useDiaryStore } from '@/stores/diary'
import { useSearchProcess } from '@/stores/search_process'
const toast = inject("toast");
// import { useRouter } from 'vue-router'


const toggleAddProduct = ref(false)

const searchProductToggle = () => {
  toggleAddProduct.value = true
  useSearchProcess().search_results = []
  useSearchProcess().search_val = ''
}

var process = reactive({
  origin_type: 1,
  origin: ''
})

const start_replace_product = (p) => {
    useDiaryStore().selected_diary_detail = p
    process.origin = p.pos_id
    searchProductToggle()
}
const expandProduct = async (product) => {
  // collapse others if any 
  useSearchProcess().search_results = [...useSearchProcess().search_results.map(p => {
    if (p.guid != product.guid) {
      p.expanded = false
    }
    return p
  })]

  product.expanded = !product.expanded
  var prod = await useDiaryStore().expandProduct(product)
  product.accesories = [...prod.accessories.map(acc => {
    acc.is_active = true
    return acc
  })]
  useSearchProcess().search_results = [...useSearchProcess().search_results.map(p => {
    if (p.guid == product.guid) {
      return {...product}
    }
    return p
  })]
}

// Replace the free line product
const add_diary_product_start = async (result) => {

    var res = await useDiaryStore().replace_free_product(result)

    if (res.toast === 'success') {
      toast.success('Produkti u shtua me sukses.');
      toggleAddProduct.value = false;
    }

}

onMounted(async () => {
    useDiaryStore().free_line_products_loading = true
    await useDiaryStore().get_free_products()
})

</script>

<style>

</style>